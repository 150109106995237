var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"template-email"}},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{ref:"filters",attrs:{"title":"Template de E-mail"}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('br'),_c('span',[_vm._v("Carregando...")])],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"type","options":_vm.templateTypes,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.templateData.type),callback:function ($$v) {_vm.$set(_vm.templateData, "type", $$v)},expression:"templateData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1281526968)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Assunto","label-for":"subject"}},[_c('validation-provider',{attrs:{"name":"subject","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.templateData.subject),callback:function ($$v) {_vm.$set(_vm.templateData, "subject", $$v)},expression:"templateData.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3158612827)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Atalho","label-for":"shortcut"}},[_c('validation-provider',{attrs:{"name":"shortcut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shortcut","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.templateData.shortcut),callback:function ($$v) {_vm.$set(_vm.templateData, "shortcut", $$v)},expression:"templateData.shortcut"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4012818875)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Arquivo pra anexar","label-for":"attachment_file"}},[_c('validation-provider',{attrs:{"name":"attachment_file","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"attachment_file","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.templateData.attachment_file),callback:function ($$v) {_vm.$set(_vm.templateData, "attachment_file", $$v)},expression:"templateData.attachment_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,445005547)}),_c('small',[_vm._v("Dica: Coloque um nome legível ao arquivo anexo. Ex: proposta.pdf, boleto.pdf")])],1)],1),(_vm.attachment_remove)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Arquivo Anexado:"}},[_c('p',[_vm._v("Para concluir a remoção do anexo, salve as alterações no botão \"Alterar\"")])])],1):_vm._e(),((_vm.templateData.attachment_path || _vm.templateData.attachment_file) && !_vm.attachment_remove)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":("Arquivo Anexado: " + ((_vm.templateData.attachment_name) ? _vm.templateData.attachment_name : ''))}},[(_vm.templateData.attachment_path)?_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isLoading || _vm.isSubmiting || _vm.isDownloading,"size":"sm"},on:{"click":function($event){return _vm.attachmentDownload(_vm.templateData.attachment_name, _vm.templateData.attachment_path)}}},[(_vm.isDownloading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Baixar ")],1):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.isLoading || _vm.isSubmiting || _vm.isDownloading,"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.attachmentRemove()}}},[_vm._v(" Remover ")])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Texto","label-for":"footer"}},[_c('validation-provider',{attrs:{"name":"Texto","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:{ 'disabled': _vm.isSubmiting },attrs:{"id":"message","rows":"10","disabled":_vm.isSubmiting,"options":_vm.editorOption},model:{value:(_vm.templateData.message),callback:function ($$v) {_vm.$set(_vm.templateData, "message", $$v)},expression:"templateData.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,367963453)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-buttons",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Voltar ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isSubmiting},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isUpdating() ? 'Alterar' : 'Incluir')+" "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_vm._v(" Variáveis conforme o usuário 489 "),_c('ul',_vm._l((_vm.variables),function(value,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(("{" + index + "}"))+" - "+_vm._s(value)+" ")])}),0)]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('small',[_c('br'),_vm._v(" Para escolher o link clicável na variável usuario_assinatura_nota_fiscal_link, usar assim:"),_c('br'),_vm._v(" {usuario_assinatura_nota_fiscal_link|"),_c('strong',[_vm._v("Consultar Nota Fiscal")]),_vm._v("}"),_c('br'),_vm._v(" Se quiser somente exibir o link: {usuario_assinatura_nota_fiscal_link}"),_c('br'),_c('br'),_vm._v(" Para obrigar a substituição de um termo, escrever as opções dentro da tag '[!]'. Ex: [!]Pacote Gold/Pacote de Serviços[!] ")])])],1)],1)],1)],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }