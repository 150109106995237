<template>
  <section id="template-email">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card
          ref="filters"
          title="Template de E-mail"
        >

          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner />
            <br>
            <span>Carregando...</span>
          </div>

          <div v-if="!isLoading">
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tipo"
                      label-for="type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="type"
                        rules="required"
                      >
                        <b-form-select
                          id="type"
                          v-model="templateData.type"
                          :options="templateTypes"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Assunto"
                      label-for="subject"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="subject"
                        rules="required|min:5"
                      >
                        <b-form-input
                          id="subject"
                          v-model="templateData.subject"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Atalho"
                      label-for="shortcut"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="shortcut"
                        rules="required"
                      >
                        <b-form-input
                          id="shortcut"
                          v-model="templateData.shortcut"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Arquivo pra anexar"
                      label-for="attachment_file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="attachment_file"
                        rules=""
                      >
                        <b-form-file
                          id="attachment_file"
                          v-model="templateData.attachment_file"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <small>Dica: Coloque um nome legível ao arquivo anexo. Ex: proposta.pdf, boleto.pdf</small>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="attachment_remove"
                    md="6"
                  >
                    <b-form-group
                      label="Arquivo Anexado:"
                    >
                      <p>Para concluir a remoção do anexo, salve as alterações no botão "Alterar"</p>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="(templateData.attachment_path || templateData.attachment_file) && !attachment_remove"
                    md="6"
                  >
                    <b-form-group
                      :label="`Arquivo Anexado: ${(templateData.attachment_name) ? templateData.attachment_name : ''}`"
                    >
                      <b-button
                        v-if="templateData.attachment_path"
                        class="mr-2"
                        :disabled="isLoading || isSubmiting || isDownloading"
                        size="sm"
                        @click="attachmentDownload(templateData.attachment_name, templateData.attachment_path)"
                      >
                        <b-spinner
                          v-if="isDownloading"
                          small
                        />
                        Baixar
                      </b-button>

                      <b-button
                        :disabled="isLoading || isSubmiting || isDownloading"
                        size="sm"
                        variant="danger"
                        @click="attachmentRemove()"
                      >
                        Remover
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Texto"
                      label-for="footer"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Texto"
                        rules=""
                      >
                        <quill-editor
                          id="message"
                          v-model="templateData.message"
                          :class="{ 'disabled': isSubmiting }"
                          rows="10"
                          :disabled="isSubmiting"
                          :options="editorOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    class="form-buttons"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="$router.go(-1)"
                    >
                      Voltar
                    </b-button>

                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="isSubmiting"
                      @click.prevent="validationForm"
                    >
                      {{ isUpdating() ? 'Alterar' : 'Incluir' }}
                      <b-spinner
                        v-if="isSubmiting"
                        small
                      />
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="6"
                    class="mt-2"
                  >
                    Variáveis conforme o usuário 489

                    <ul>
                      <li
                        v-for="(value, index) in variables"
                        :key="index"
                      >
                        {{ `{${index}\}` }} - {{ value }}
                      </li>
                    </ul>
                  </b-col>

                  <b-col
                    cols="6"
                    class="mt-2"
                  >
                    <small>
                      <br>
                      Para escolher o link clicável na variável usuario_assinatura_nota_fiscal_link, usar assim:<br>
                      {usuario_assinatura_nota_fiscal_link|<strong>Consultar Nota Fiscal</strong>}<br>
                      Se quiser somente exibir o link: {usuario_assinatura_nota_fiscal_link}<br>
                      <br>
                      Para obrigar a substituição de um termo, escrever as opções dentro da tag '[!]'. Ex: [!]Pacote Gold/Pacote de Serviços[!]
                    </small>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BButton,
  BBreadcrumb,
  BSpinner,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { maskDate } from '@/utils/masks'
import EmailService from '@/services/emailService'
import { formatDateDbToView } from '@/utils/helpers'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import downloadFile from '@/mixins/downloadFile'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    BBreadcrumb,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    quillEditor,
  },
  directives: {
    // Ripple,
  },
  mixins: [
    downloadFile,
  ],
  props: {

  },
  data() {
    return {
      isLoading: true,
      isDownloading: false,
      isSubmiting: false,
      editorOption: {
        syntax: false,
        placeholder: 'Mensagem',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link'],
          ],
        },
      },
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Template de E-mail',
          to: { name: 'tools-email-template' },
        },
        {
          text: this.isUpdating() ? 'Alterar' : 'Adicionar',
          active: true,
        },
      ],
      emailService: null,
      userId: 489,
      variables: [],
      templateData: {
        id: null,
        type: '',
        subject: '',
        shortcut: '',
        message: '',
        attachment_file: null,
        attachment_path: '',
        attachment_name: '',
      },
      attachment_remove: false,
      templateTypes: [],

      maskDate,

      required,
      email,
      formatDateDbToView,
    }
  },

  async created() {
    this.emailService = new EmailService()

    await this.getTypes()
    await this.getVariables()

    if (this.isUpdating()) {
      this.getTemplate()
    } else {
      this.isLoading = false
    }
  },

  methods: {
    isUpdating() {
      return this.$route.params.id !== undefined
    },

    attachmentRemove() {
      this.attachment_remove = true
      this.attachment_file = null
    },

    attachmentDownload(name, file) {
      this.isDownloading = true
      return this.emailService.getUrlDownloadAttachment(name, file).then(response => {
        this.isDownloading = false
        this.openModalToSaveFile(response, name)
      })
    },

    async getTypes() {
      this.isLoading = true
      return this.emailService.listTypes().then(response => {
        this.isLoading = false
        this.templateTypes = response.data.data.types
      })
    },

    async getVariables() {
      this.isLoading = true
      return this.emailService.listVariables(this.userId).then(response => {
        this.isLoading = false
        this.variables = response.data.data.variables
      })
    },

    getTemplate() {
      this.isLoading = true
      this.emailService.getTemplate(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.templateData = response.data.data.template
          this.templateData.message = this.templateData.message.split('\n').join('<BR>')
        }
        this.isLoading = false
      })
    },

    postTemplate() {
      this.isSubmiting = true
      const formData = new FormData()
      Object.entries(this.templateData).forEach(item => {
        if (this.isUpdating() || item[0] !== 'id') {
          if ((item[0] === 'attachment_file' && !item[1])) {
            return
          }
          formData.append(item[0], item[1])
        }
      })
      formData.append('attachment_remove', this.attachment_remove ? 1 : 0)

      if (this.isUpdating()) {
        this.emailService.updateTemplate(this.$route.params.id, formData).then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Template de e-mail alterado!',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$router.go(-1)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: 'Não foi possível alterar o Template de e-mail!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          this.isSubmiting = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.isSubmiting = false
        })
      } else {
        this.emailService.postTemplate(formData).then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Template de e-mail inserido!',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$router.go(-1)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: 'Não foi possível inserir o Template de e-mail!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          this.isSubmiting = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.isSubmiting = false
        })
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postTemplate()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              text: 'Existem erros de validação no formulário',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #template-email{
      #message {
        height: 350px;
        padding-bottom: 45px;

        &.disabled{
          background-color: $input-disabled-bg;
        }
      }
  }

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
